import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzBreadCrumbComponent, NzBreadCrumbItemComponent } from 'ng-zorro-antd/breadcrumb';
import { NzIconDirective } from 'ng-zorro-antd/icon';

/**
 * Component to show breadcrumbs line
 */
const _forTrack0 = ($index, $item) => $item.key;
function CrmBreadcrumbsComponent_For_2_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 3);
  }
  if (rf & 2) {
    const bc_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("nzType", bc_r1.icon);
  }
}
function CrmBreadcrumbsComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-breadcrumb-item", 1)(1, "a", 2);
    i0.ɵɵtemplate(2, CrmBreadcrumbsComponent_For_2_Conditional_2_Template, 1, 1, "i", 3);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "crmTranslate");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_15_0;
    const bc_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵclassProp("crm-breadcrumb-item-link-route", bc_r1.route);
    i0.ɵɵproperty("routerLink", bc_r1.route ? "/" + bc_r1.route : null)("queryParams", bc_r1.queryParams)("queryParamsHandling", bc_r1.queryParamsHandling);
    i0.ɵɵadvance();
    i0.ɵɵconditional(2, bc_r1.icon ? 2 : -1);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 7, (tmp_15_0 = bc_r1.title) !== null && tmp_15_0 !== undefined ? tmp_15_0 : bc_r1.key), " ");
  }
}
class CrmBreadcrumbsComponent {
  static {
    this.ɵfac = function CrmBreadcrumbsComponent_Factory(t) {
      return new (t || CrmBreadcrumbsComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmBreadcrumbsComponent,
      selectors: [["crm-breadcrumbs"]],
      inputs: {
        breadcrumbs: "breadcrumbs"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 0,
      consts: [[1, "crm-breadcrumb"], [1, "crm-breadcrumb-item"], [1, "crm-breadcrumb-item-link", 3, "routerLink", "queryParams", "queryParamsHandling"], ["nz-icon", "", 3, "nzType"]],
      template: function CrmBreadcrumbsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nz-breadcrumb", 0);
          i0.ɵɵrepeaterCreate(1, CrmBreadcrumbsComponent_For_2_Template, 5, 9, "nz-breadcrumb-item", 1, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.breadcrumbs);
        }
      },
      dependencies: [RouterLink, CrmTranslatePipe, NzBreadCrumbComponent, NzBreadCrumbItemComponent, NzIconDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmBreadcrumbsComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-breadcrumbs',
      template: `
    <nz-breadcrumb class="crm-breadcrumb">
      @for (bc of breadcrumbs; track bc.key) {
        <nz-breadcrumb-item class="crm-breadcrumb-item">
          <a
            class="crm-breadcrumb-item-link"
            [class.crm-breadcrumb-item-link-route]="bc.route"
            [routerLink]="bc.route ? '/' + bc.route : null"
            [queryParams]="bc.queryParams"
            [queryParamsHandling]="bc.queryParamsHandling"
          >
            @if (bc.icon) {
              <i nz-icon [nzType]="bc.icon"></i>
            }
            {{ bc.title ?? bc.key | crmTranslate }}
          </a>
        </nz-breadcrumb-item>
      }
    </nz-breadcrumb>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [RouterLink, CrmTranslatePipe, NzBreadCrumbComponent, NzBreadCrumbItemComponent, NzIconDirective]
    }]
  }], null, {
    breadcrumbs: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmBreadcrumbsComponent };
